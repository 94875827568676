@import '../../../styles/variables.scss';

.helpIcon {
  $size: 16px;

  display: inline-block;
  width: $size;
  height: $size;
  mask-image: url('./helpIcon.svg');
  mask-size: $size;
  background-color: $cerulean;
  transform: translateX(-2px);

  &.clickable {
    cursor: pointer;
  }
}
