@import '../../styles/spacing';
@import '../../styles/variables';
@import '../../styles/mixins';

$transition-duration: 0.2s;

.dashboardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0; // give flex box full flexibility in distinguishing element's height
  position: relative;
  flex: 1 1 auto;
}

.mapTableContainer {
  display: grid;
  position: relative;
  height: 100%;
  column-gap: 1px;
  background-color: var(--primary-color);
  isolation: isolate;
  overflow: hidden;

  .map {
    height: 100%;
    width: calc(100% - $mapTableWidth);
  }

  &FullMap {
    .map {
      width: 100%;
    }
  }
}

.mailHeader {
  width: 4rem;
  height: 100%;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

.tableButtonContainer {
  position: absolute;
  right: 0px;
  height: 100%;
  width: $mapTableWidth;
  transition: transform $transition-duration;
  transform: translateX(0);

  &Hidden {
    transform: translateX($mapTableWidth);
  }
}

.tableContainer {
  height: 100%;
  transition: visibility $transition-duration;
  visibility: visible;

  &Hidden {
    visibility: hidden;
  }
}

.visibilityButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 100%;
  width: 2rem;
  height: 2rem;
  background-color: $cerulean;
  border-radius: 50% 0 0 50%;
  user-select: none;
  outline: none;
  padding: 0;
}

.visibilityIcon {
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
  background-color: $white;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 800;
  box-shadow: 0 0 2px 1px rgba($black, 0.1);
  transition: transform $transition-duration;

  svg {
    fill: $cerulean;
  }

  &Rotated {
    transform: rotate(180deg);
  }
}

.legendContainer {
  position: absolute;
  bottom: spacing(8);
  left: spacing(4);
  transition: right $transition-duration;
}

.helpIconSelected {
  background-color: $white;
}

.portStatusCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: spacing(1);
  width: 4.5rem;
}
