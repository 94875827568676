@import '../../styles/mixins';
@import '../../styles/spaced';
@import '../../styles/variables';
@import '../../styles/spacing';

$icon-size: 20px;
$heading-font-size: 20px;

.container {
  padding: spacing(4);
}

.heading {
  font-size: $heading-font-size;
}

.link {
  color: var(--text-color);
}
