@import '../../styles/variables.scss';

.imagePlaceholder {
  color: $lightgrey;
  position: relative;
  max-width: 100%;
  aspect-ratio: 3/2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $xx-small-font-size;

  svg {
    fill: $lightgrey;
  }
}
