@import 'toggleTheme';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spacing';

.toggleContainer {
  @include clickable();

  display: flex;
  align-items: center;
}

.outerLabel {
  font-size: $x-small-font-size;
  font-family: $font-family-strong;
  text-transform: uppercase;
  pointer-events: none;
  color: var(--text-color);
  margin-right: spacing(3);
}

.toggle {
  @include toggleTheme(var(--disabled-color), $mediumblue, $white, $white, $white, $white, var(--disabled-color));

  &:hover {
    outline: none;
  }
}

.hiddenInput {
  @include visuallyHidden;
}
