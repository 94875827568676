@import '../../../../styles/variables';

.label {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: var(--text-color);
  font-family: $font-family-strong;

  .dot {
    $size: 12px;

    display: inline-block;
    width: $size;
    height: $size;
    margin-right: $size;
    border-radius: $size / 2;
    transform: translateY(-1px);
  }
}
