@import './NLRTM-font-family';

//==================
// Colors
//==================

// Blues
$catalinaBlue: #073670;
$darkerBlue: #011129;
$darkblue: #001f4b;
$mediumblue: #0087c6;
$lightblue: #429ad1;
$lightblueAlternative: #009cd6; // This one is slightly lighter than $lightblue, but comes out better in the operational dashboard.
$veryLightBlue: #536785;
$selectedLightBlue: #e0eef4;
$cerulean: #0693ca;
$oxford-blue: #334053;

// White and greys
$white: white;
$alto: #dedede;
$broken-white: #f8f8f8;
$verylightgrey: #cfcfb0;
$lightgrey: #efefef;
$mediumgrey: #bbb;
$greyish-orange: #c0bbb5;
$darkgrey: #696e7d;
$bluegray: rgb(37, 50, 72);
$transparent-white-20: rgba(255, 255, 255, 0.2);
$ebb: #e6e4e2;
$polar: #e8f5fa;
$cloudy: #ccc8c5;

$magenta: #e6007e;
$red: #d0021b;
$darkred: #db392a;
$green: #46be41;
$black: black;
$corn: #e6c300;
$mostly-pure-yellow: #ffd800;
$text-color-alt: $white;
$blue-lagoon: #007e91;

$shadowColor: rgba($black, 0.3);

//==================
// Typography
//==================
$title-font-size: 24px;
$title-line-height: 24px;
$default-font-size: 16px;
$default-line-height: 1.25em;
$small-font-size: 14px;
$xx-small-font-size: 9px;
$x-small-font-size: 12px;
$table-header-font-size: 10px;
$table-cell-font-size: 12px;
$font-family-strong: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
$font-family-medium: 'NLRTM-Medium', 'Trebuchet MS', Arial, sans-serif;
$font-family-regular: 'NLRTM-Regular', 'Trebuchet MS', Arial, sans-serif;
$theme-font-family: $font-family-regular;
$font-family-headings: $font-family-strong;
$font-family-sub-headings: $font-family-strong;

$table-line-height: 1rem;

//==================
// Z-Indexes
//==================
$account-menu-index: 100;
$account-menu-overlay-index: 99;

//==================
// Sizes
//==================
$headerHeight: 50px;
$mapTableWidth: 40rem;

//==================
// Shadows
//==================
$shadow-default: 0 4px 4px $shadowColor;
