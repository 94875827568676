@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';

.legend {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba($black, 50%);
  min-width: 140px;
  background-color: var(--tertiary-color);
  display: flex;
  flex-direction: column;
  padding: spacing(3) spacing(4);
  gap: spacing(3);

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: spacing(3);

    .icon {
      width: 15px;
      height: 24px;
    }

    .text {
      font-size: $small-font-size;
      color: var(--text-color);
    }
  }
}
