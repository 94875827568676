@import '../../styles/variables';
@import '../../styles/spacing';
@import '../../styles/breakpoints.scss';

$headerHeight: 48px;

@mixin row-label($color) {
  $labelWidth: 9px;

  &:before {
    content: '';
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    width: $labelWidth;
    margin-right: -$labelWidth;
    height: 100%;
    background-color: rgba($color, 0.5);
  }
}

.scrollableArea {
  will-change: unset !important; // needs to overwrite the inline style on outer react-window container
  background-color: var(--primary-color);
}

.table {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  border-spacing: 0;
  font-family: $font-family-medium;
  color: var(--text-color);
}

.body {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  position: relative;
}

.row {
  min-width: 100% !important;
  width: auto !important;
  position: absolute !important;
  &.highlighted {
    background-color: rgba($mediumblue, 0.25);
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      &:not(.highlighted) {
        background-color: rgba($mediumblue, 0.1);
      }
    }
  }

  &.label {
    &Automatic {
      @include row-label($blue-lagoon);
    }

    &Cancelled {
      @include row-label($darkred);
    }
  }

  .cell,
  .headerCell {
    &.divider {
      @include breakpoint(screen-xxl, max) {
        border-right: 3px solid var(--border-color);
      }
    }

    &.sticky {
      position: sticky;
    }

    &:first-child {
      padding-left: spacing(5);
      left: 0;
    }

    &:last-child {
      padding-right: spacing(5);
      position: relative;
    }
  }

  .cell.sticky {
    z-index: 1;
    background-color: var(--primary-color);
  }

  .headerCell.sticky {
    z-index: 2;
    background-color: $darkerBlue;
  }
}

.headerCell,
.cell {
  padding: spacing(3) spacing(2);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: left;
}

.headerCell {
  text-transform: uppercase;
  font-size: $table-header-font-size;
  font-family: $font-family-strong;
  background-color: $darkerBlue;
  color: $white;
}

.cell {
  font-size: $table-cell-font-size;
  font-family: $font-family-regular;
  line-height: $table-line-height;
}

.head {
  position: sticky;
  top: 0;
  left: 0;
  height: $headerHeight;
  z-index: 3;
}

.headCellInner {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.headCellInnerSortable {
  white-space: nowrap;
  cursor: pointer;
}

.headCellInnerFilterable {
  @extend .headCellInner;
  margin-top: -0.125em;
  padding: 0.25em 1.25em 0.125em 0.25em;
  height: 100%;
  width: 100%;
  line-height: 2em;
  text-align: left;
  text-transform: uppercase;
  font-family: $font-family-strong;
  font-size: $table-header-font-size;
  line-height: inherit;
  color: $white;

  &::placeholder {
    opacity: 1;
    color: $white;
  }

  &:hover,
  &:focus,
  &:not([value='']) {
    + .icon {
      color: $lightblue;
    }
  }

  &:hover {
    box-shadow: 0 1px $lightblue;
  }

  &:focus,
  &:not([value='']) {
    outline: 0;
    border: 0;
    color: $darkblue;
    box-shadow: 0 1px $white, 0 -1px $white;
    background: $white;

    &::placeholder {
      color: $darkgrey;
      font-family: $font-family-regular;
    }
  }
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: spacing(5);
  font-family: $font-family-strong;
  font-size: $x-small-font-size;
}

.filterContainer {
  position: relative;
  width: 100%;
  color: $white;

  .icon {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    padding: 0;
    color: $white;
    height: 1em;
    width: 1em;
  }

  .clear {
    cursor: pointer;
  }

  .search {
    pointer-events: none;
  }
}

.filterDropoutContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: spacing(-3);
  margin-bottom: spacing(-3);
  height: 100%;
}

.filterDropoutButton {
  padding-top: spacing(3);
  padding-bottom: spacing(3);
  font-size: $table-header-font-size;
  font-family: $font-family-strong;
  line-height: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: $white;

  svg {
    margin-left: 2px;
    transform: translateY(-1px);
  }

  &.open {
    svg {
      transform: translateY(-1px) rotate(180deg);
    }
  }
}

.filterDropoutMenu {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 100%;
  margin: 0;
  border: 1px solid var(--border-color);
  padding: 0;
  color: var(--text-color);
  list-style-type: none;
  background-color: var(--secondary-color);
}

.filterDropoutMenuButton {
  display: block;
  min-width: 100%;
  width: min-content;
  padding: spacing(2) spacing(5);
  font-size: $x-small-font-size;
  font-family: $font-family-strong;
  text-transform: uppercase;
  color: var(--text-color);

  &:hover,
  &:focus,
  &.active {
    background: $lightblue;
  }

  &.activeValue {
    display: inline-block;
    width: 1em;
    text-align: center;
  }
}
