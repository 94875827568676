@import '../../../styles/variables';
@import '../../../styles/spacing';

.backToTopButton {
  $radius: 4px;

  position: fixed;
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
  font-size: $xx-small-font-size;
  font-family: $font-family-strong;
  color: var(--text-color);
  background-color: var(--tertiary-color);
  padding: spacing(1) spacing(2);
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  text-transform: uppercase;
  z-index: 1;
}
