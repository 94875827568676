@import '../../../../styles/variables';

.container {
  display: grid;
  justify-items: start;
  max-width: 100%;
}

.vesselName {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.isOverflowing {
    border-bottom: 1px dashed $white;
  }
}
