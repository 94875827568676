@import '../../styles/variables';

$pill-line-height: 0.75rem;

.pill {
  display: inline-flex;
  text-transform: uppercase;
  margin-top: ($table-line-height - $pill-line-height);
  margin-bottom: ($table-line-height - $pill-line-height);
  padding: (($table-line-height - $pill-line-height) / 2) 2px;
  border-radius: 2px;
  font-size: $xx-small-font-size;
  font-family: $font-family-strong;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  line-height: $pill-line-height;
  white-space: nowrap;
  background-color: $magenta;
  color: $white;
}

.pillInner {
  display: inline-block;
  line-height: 1;
}
