@import '../../../../styles/spacing';
@import '../../../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: start;
  align-content: center;
  grid-template-rows: repeat(2, min-content);
  grid-row-gap: spacing(1);
}

.smallGap {
  grid-row-gap: 3px;
}
