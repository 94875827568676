@import '../../../styles/spacing';
@import '../../../styles/variables';
@import '../../../styles/mixins';

$row-label-height: 4rem;

.helpDropout {
  min-width: 20rem;
  background: var(--block-color);
  font-size: $x-small-font-size;
  font-family: $font-family-regular;
  color: var(--text-alt-color);
  transform: translateX(-25px);

  &Arrow {
    @include css-triangle(top, var(--block-color), 5px, 10px);
  }

  .close {
    position: absolute;
    padding: 0;
    top: spacing(1.5);
    right: spacing(1.5);
    color: $darkblue;
    appearance: none;
  }

  &.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    overflow: scroll;
    transform: none;
    z-index: 100;
    padding: 3rem 2rem 1rem 1rem;

    .close {
      top: spacing(2);
      right: spacing(2);
    }
  }

  .title {
    margin: spacing(1) 0;
    font-size: $small-font-size;
    font-family: $font-family-strong;
    color: $darkblue;
  }

  .description {
    margin: 0;
  }

  .section {
    margin: 0 0 spacing(3);
    padding: spacing(1) 0 spacing(1) spacing(4);
    position: relative;
    width: 100%;
    min-height: $row-label-height;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: spacing(2);
      height: 100%;
    }

    &.automatic {
      &:before {
        background-color: rgba($blue-lagoon, 0.5);
      }
    }
    &.cancelled {
      &:before {
        background-color: rgba($darkred, 0.5);
      }
    }

    .name {
      margin: 0;
      font-size: $x-small-font-size;
      text-transform: uppercase;
      font-family: $font-family-strong;
    }
  }
}

.textContainer {
  margin: 0 0 spacing(5);
  padding-left: spacing(4);
}

.portStatusList,
.movementTypeList {
  margin: spacing(1) 0 0;
  padding: 0;
  list-style-type: none;
}

.portStatusListItem {
  display: flex;
  align-items: center;
  gap: spacing(1);
  margin-bottom: spacing(1);
}

.movementTypeListItem {
  margin-bottom: spacing(1);

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
  }

  &.inbound::before {
    background-color: $green;
  }

  &.shifting::before {
    // Deliberately a bit darker than the actual shifting colour, because the bg is the same colour. This makes it stand out against the background, while still being grey.
    background-color: $mediumgrey;
  }

  &.outbound::before {
    background-color: $lightblue;
  }
}

.portStatusText {
  font-family: $font-family-regular;
}
