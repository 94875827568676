@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/z-indices';
@import '../../styles/spacing';

$spinnerSize: 1em;
$modalPadding: 2.5rem;

.modalParent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $modal;
  padding: 0.75rem;
  box-sizing: border-box;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.6);
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 32rem;
  max-height: 100vh;
  background-color: $white;
  z-index: $modal + 1;
}

.content {
  padding: $modalPadding;
}

.title {
  font-family: $font-family-strong;
  font-size: $title-font-size;
  line-height: $title-line-height;
  color: $darkblue;
  margin: 0 0 0.375em;
}

.text {
  margin: 0;
  font-family: $font-family-regular;
  font-size: $default-font-size;
  line-height: $default-line-height;
  color: $black;
  white-space: pre-wrap;
}

.error {
  color: $red;
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px $lightgrey solid;
  padding: 1rem;
}

.button {
  font-family: $font-family-strong;
  font-size: $default-font-size;
  padding: 1em 1.5em;
  border-radius: 3px;
}

.button + .button {
  margin-left: 1rem;
}

.cancelButton {
  @include button($mediumgrey, $white, $white, $darkgrey);
}

.confirmButton {
  @include button($darkblue, $white, $white, $darkerBlue);
}

.loadingStatus {
  display: flex;
  align-items: center;
}

.lightBackgroundSpinner {
  @include spinner($spinnerSize, 0, $white, transparent);
}

.loaderInline {
  position: relative;
  width: $spinnerSize;
  height: $spinnerSize;
  padding-right: 0.5rem;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: spacing(3);
}
