@import '../../styles/spacing.scss';
@import '../../styles/variables.scss';

.subtitle {
  margin-top: spacing(10);
  margin-bottom: spacing(2);
  color: $cerulean;
  font-size: 22px;

  &:first-of-type {
    margin-top: spacing(8);
  }
}

.title {
  margin-bottom: 0px;
  font-size: 26px;
}
