@import '../../styles/variables';
@import '../../styles/spacing';

$card-spacing: spacing(4);

.card {
  flex: 0 1 100%;
  padding: $card-spacing;
  width: 100%;
  max-width: 40rem;
  background: $white;
  box-shadow: $shadow-default;
}

.cardTitle {
  margin: 0;
  padding-bottom: $card-spacing;
  border-bottom: 1px solid $lightgrey;
}
