@import './NLRTM-font-family';
@import './variables';

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  background-color: var(--primary-color);
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

  font-family: $font-family-regular;
  font-size: $default-font-size;
  font-weight: normal;
}

body {
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
  font-weight: normal;
}

input,
textarea,
select,
button {
  font-weight: normal;
  background: transparent;
  border: none;
}

button {
  cursor: pointer;
}

strong,
th {
  font-weight: normal;
  font-family: $font-family-strong;
}

table {
  table-layout: fixed;
  border-spacing: 0;
  border: 0;
}

thead,
tbody,
tfoot,
th,
td {
  border: 0;
  padding: 0;
}

:global(#root) {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:global(.mapboxgl-ctrl) {
  background-color: var(--tertiary-color);

  :global(button.mapboxgl-ctrl-zoom-in) {
    &:hover {
      background-color: var(--primary-color);
    }

    :global(.mapboxgl-ctrl-icon) {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23dedede;' d='M 10 6 C 9.446 6 9 6.4459904 9 7 L 9 9 L 7 9 C 6.446 9 6 9.446 6 10 C 6 10.554 6.446 11 7 11 L 9 11 L 9 13 C 9 13.55401 9.446 14 10 14 C 10.554 14 11 13.55401 11 13 L 11 11 L 13 11 C 13.554 11 14 10.554 14 10 C 14 9.446 13.554 9 13 9 L 11 9 L 11 7 C 11 6.4459904 10.554 6 10 6 z'/%3E %3C/svg%3E");
    }
  }
  :global(button.mapboxgl-ctrl-zoom-out) {
    border-top-color: var(--border-color);

    &:hover {
      background-color: var(--primary-color);
    }

    :global(.mapboxgl-ctrl-icon) {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23dedede;' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E %3C/svg%3E");
    }
  }
}
