//https://rimdev.io/making-media-query-mixins-with-sass/

// A map of breakpoints (borrowed from Twitter Bootstrap).
//  https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss#L297
$breakpoints: (
  screen-xs: 0,
  screen-sm: 576px,
  screen-md: 768px,
  screen-lg: 992px,
  screen-xl: 1200px,
  screen-xxl: 1400px,
) !default;

@mixin breakpoint($breakpoint, $direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @if $direction == max {
      @media (max-width: ($breakpoint-value)) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint-value + 1) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint-value) {
        @content;
      }
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    @if $direction == max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint) {
        @content;
      }
    }
  }
}
