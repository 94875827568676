@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/z-indices';
@import '../../styles/spacing';
@import '../../styles/strip-unit';

$dropper-background: var(--secondary-color);
$dropper-arrow-size: 5px;
$base-spacing: 0.25rem;
$sm-min: 30rem;
$triangle-space: 2 * $dropper-arrow-size;

.dropoutDropper {
  box-sizing: border-box;
  position: absolute;
  color: $white;
  z-index: $dropDownMenuIndex + 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  filter: drop-shadow(0 0 1px var(--border-color));
  user-select: none;
  text-transform: initial;

  &.horizontal {
    flex-direction: row;
  }

  .triangle {
    margin: auto;
  }

  &:not(.horizontal) {
    .triangle {
      &.offsetLeft {
        transform: translateX(-50%);
      }

      &.offsetRight {
        transform: translateX(50%);
      }
    }
  }

  &.horizontal {
    .triangle {
      &.offsetLeft {
        transform: translateY(-50%);
      }

      &.offsetRight {
        transform: translateY(50%);
      }
    }
  }

  &:not(.horizontal) {
    .triangle.top {
      @include css-triangle(top, $dropper-background, $dropper-arrow-size, $dropper-arrow-size * 2);
    }

    .triangle.bottom {
      @include css-triangle(bottom, $dropper-background, $dropper-arrow-size, $dropper-arrow-size * 2);
    }

    &.top {
      top: calc(100% + #{$triangle-space} - #{$dropper-arrow-size});

      &.triangleSpace {
        top: calc(100% + #{$triangle-space});
      }
    }

    &.bottom {
      flex-direction: column-reverse;
      bottom: calc(100% + #{$triangle-space} - #{$dropper-arrow-size});

      &.triangleSpace {
        bottom: calc(100% + #{$triangle-space});
      }
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.horizontal {
    .triangle.top {
      @include css-triangle(left, $dropper-background, $dropper-arrow-size * 2, $dropper-arrow-size);
    }

    .triangle.bottom {
      @include css-triangle(right, $dropper-background, $dropper-arrow-size * 2, $dropper-arrow-size);
    }

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.left {
      left: calc(100% + #{$triangle-space} - #{$dropper-arrow-size});

      &.triangleSpace {
        left: calc(100% + #{$triangle-space});
      }
    }

    &.right {
      flex-direction: row-reverse;
      right: calc(100% + #{$triangle-space} - #{$dropper-arrow-size});

      &.triangleSpace {
        right: calc(100% + #{$triangle-space});
      }
    }
  }
}

.content {
  flex-grow: 1;
  padding: $base-spacing * 5;
  width: 100vw;
  background: $dropper-background;
  color: var(--text-color);

  @media screen and (min-width: $sm-min) {
    max-width: 12rem;
    min-width: max-content;
  }
}

:export {
  horizontalArrowOffset: strip-unit(spacing(6));
}
