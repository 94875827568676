@use 'sass:math';
@import 'animations';
@import 'variables';
@import './shapes/round';
@import './breakpoints.scss';

@mixin circumference($size, $borderColor) {
  @include round($size);
  border: 2px $borderColor solid;
  box-sizing: border-box;
}

@mixin circumferenceWithImage($size, $verticalMargin, $borderColor, $image) {
  @include circumference($size, $borderColor);
  background-image: $image;
  background-repeat: no-repeat;
  background-position: center;
  margin: $verticalMargin auto;
}

@mixin spinner($size, $margin, $backgroundBorderColor: $mediumgrey, $foregroundBorderColor: $black) {
  @include circumference($size, $backgroundBorderColor);
  border-bottom-color: $foregroundBorderColor;
  animation: spinner-rotate 0.75s linear infinite;
  margin: $margin auto;
}

@mixin centerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin clickable {
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: default;
  }
}

@mixin visuallyHidden {
  position: absolute;
  width: 1px;
}

@mixin button($primary-color, $secondary-color, $tertiary-color, $quarternary-color) {
  display: inline-block;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  padding: 0.75rem 1rem;
  font-family: $font-family-strong;
  line-height: 1;
  text-align: center;
  color: $secondary-color;
  background: $primary-color;

  &:not(:disabled) {
    @include clickable();
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    background: $quarternary-color;
    color: $tertiary-color;
  }

  &:focus:not(:disabled) {
    outline: 1px solid $secondary-color;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--primary-color);
    background-color: var(--disabled-color);
  }
}

@mixin input($inputHeight: 35px) {
  width: 100%;
  height: $inputHeight;
  padding: spacing(1) spacing(2);
  background-color: $white;
  appearance: none;
  outline: none;
  border: none;
  font-family: $font-family-regular;
  font-size: $small-font-size;

  @include breakpoint(screen-md, max) {
    font-size: $default-font-size;
  }
}

@mixin preventMarginCollapseWithPreviousElement {
  &:before {
    content: ' ';
    display: table;
    height: 0;
  }
}

@mixin overflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS Triangles Mixin
@mixin css-triangle($direction, $color, $height, $width) {
  // Base styles for all triangles
  border-style: solid;
  height: 0;
  width: 0;
  // Cardinal Directions - can't be scalene this way
  @if ($direction == 'top') {
    border-color: transparent transparent $color transparent;
    border-width: 0 math.div($width, 2) $height ($width, 2);
  }
  @if ($direction == 'bottom') {
    border-color: $color transparent transparent transparent;
    border-width: $height math.div($width, 2) 0 ($width, 2);
  }
  @if ($direction == 'right') {
    border-color: transparent transparent transparent $color;
    border-width: math.div($height, 2) 0 math.div($height, 2) $width;
  }
  @if ($direction == 'left') {
    border-color: transparent $color transparent transparent;
    border-width: math.div($height, 2) $width math.div($height, 2) 0;
  }
  // Ordinal Directions - can be scalene this way!
  @if ($direction == 'top-left') {
    border-color: $color transparent transparent transparent;
    border-width: $height $width 0 0;
  }
  @if ($direction == 'top-right') {
    border-color: transparent $color transparent transparent;
    border-width: 0 $width $height 0;
  }
  @if ($direction == 'bottom-left') {
    border-color: transparent transparent transparent $color;
    border-width: $height 0 0 $width;
  }
  @if ($direction == 'bottom-right') {
    border-color: transparent transparent $color transparent;
    border-width: 0 0 $height $width;
  }
}
