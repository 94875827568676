@import '../../styles/variables';
@import '../../styles/spacing';

.arrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: spacing(1);
}

.arrow {
  display: inline-block;
  width: 11px;
  height: 11px;
}

.arrowClosed {
  color: $red;
}

.arrowOpen {
  color: $green;
}
