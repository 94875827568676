@import '../../../../styles/variables';
@import '../../../../styles/spacing';
@import '../../../../styles/mixins';

.disabled {
  cursor: help;
}

.overflowDropout {
  background: var(--block-color);
  padding: 0;
  margin-top: spacing(-3);

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: spacing(-4);
    right: spacing(2);
    @include css-triangle(top, var(--block-color), 5px, 10px);
  }
}

.overflowMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: rgba($lightblue, 0.2);
}

.dotsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
}

.section {
  position: relative;
  display: flex;

  .sectionAction {
    color: $darkblue;
    font-size: $small-font-size;
    font-family: $font-family-regular;
    padding: spacing(4);
    width: 100%;
    height: auto;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;

    &.checkboxDisabled {
      color: $mediumgrey;
      cursor: not-allowed;
    }
  }
  &:last-child {
    border-top: 1px solid $mediumgrey;
    .sectionAction {
      padding: spacing(4) spacing(4) spacing(4) 53px;
    }
  }
}
