@import '../../styles/variables';
@import '../../styles/spacing';
@import '../../styles/mixins';
@import '../../styles/breakpoints.scss';

.buttonContainer {
  display: flex;
  border-left: solid 1px var(--border-color);
  align-items: center;
}

.dropdownButton {
  @include clickable();

  width: $headerHeight + spacing(2);
  height: $headerHeight;

  &.textButton {
    padding: 0 spacing(4);
    width: auto;
    font-size: $x-small-font-size;
    font-family: $font-family-strong;
    text-transform: uppercase;
    color: var(--text-color);
  }
  &.clicked {
    background-color: var(--primary-color);
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.dropdownContainer {
  display: flex;
  height: calc(100vh - #{$headerHeight});
  max-width: 100vw;
  flex-direction: column;
  position: absolute;
  background-color: var(--primary-color);
  top: 100%;
  padding: spacing(5) 0 spacing(3);
  right: 0;
  overflow: auto;

  @include breakpoint(screen-md, max) {
    width: 100vw;
    height: calc(100dvh - #{$headerHeight});
    padding-bottom: spacing(20);
  }
}

.searchDropdownSetting {
  $searchItemHeight: 35px;
  border: 1px solid var(--dropdown-border-color);

  position: relative;

  .searchInput {
    @include input($searchItemHeight);
  }

  .searchIcon {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: spacing(2);
  }

  .searchDropdown {
    display: flex;
    width: 100%;
    border-top: solid 1px $darkblue;
    flex-direction: column;
    position: absolute;
    background-color: $white;
    top: 100%;
    right: 0;

    .searchItem {
      display: flex;
      height: $searchItemHeight;
      align-items: center;
      padding: spacing(1) spacing(2);
      color: $darkblue;

      .itemCheckbox {
        color: $darkblue;
      }
    }

    .searchSection {
      width: 100%;
      position: relative;
      max-height: 5 * $searchItemHeight;
      overflow-y: auto;
    }

    .searchDivider {
      width: 100%;
      height: 0;
      border-top: dashed 1px $darkblue;
    }
  }
}

.section {
  width: 400px;
  max-width: 100vw;
  padding: 0 spacing(3) spacing(6);

  @include breakpoint(screen-md, max) {
    width: 100%;
  }

  ::marker,
  ::-webkit-details-marker {
    display: none;
  }

  &.columnSection {
    width: 250px;
  }

  .header {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &.isStatic {
      pointer-events: none;
    }

    .sectionTitle {
      color: var(--text-color);
      margin: 0;
      font-size: $default-font-size;
      font-family: $font-family-strong;
    }
  }

  &[open] {
    .header {
      margin-bottom: spacing(3);

      .sectionToggle {
        svg {
          transform: translateY(-2px) rotate(180deg);
        }
      }
    }
  }
}

.standaloneCheckbox {
  padding: spacing(1) 0 0 spacing(2);
}

.list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.listItem {
  display: flex;
  align-items: center;
  padding: spacing(1) spacing(2);

  &.isDragging {
    opacity: 0;
  }

  .dragHandler {
    margin-left: auto;
    cursor: move;

    &.isDisabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}

.resetButton {
  margin: spacing(3) auto;
  font-size: $x-small-font-size;
  font-family: $font-family-strong;
  text-transform: uppercase;
  text-decoration: underline;
  color: var(--text-color);
  appearance: none;
}

.applyButtonContainer {
  display: grid;
  margin: spacing(3) spacing(3) 0;
}

.applyButton {
  @include button($lightblueAlternative, $white, $white, $darkerBlue);

  justify-self: stretch;
  font-family: $font-family-strong;
  font-size: $default-font-size;
  border-radius: 3px;
}

.filterIndicator {
  display: inline-block;
  margin-left: spacing(1);
  border-radius: 50%;
  border: 1px solid var(--text-color);
  width: spacing(2);
  height: spacing(2);

  &.filterActive {
    border-color: $green;
    background: $green;
  }
}
