@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spaced';
@import '../../styles/spacing.scss';

.collapsable {
  padding: spacing(4) 0;
  border-bottom: var(--border-color) 1px solid;
}

.title {
  @include clickable();
  @include spaced-horizontal(spacing(2));
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--text-color);
  padding: 0;
  text-align: left;
}

.titleText {
  flex-grow: 1;
  flex-shrink: 1;

  font-family: $font-family-regular;
  font-size: $default-font-size;
  line-height: 23px;
}

.content {
  @include preventMarginCollapseWithPreviousElement();

  font-family: $font-family-regular;
  font-size: $small-font-size;
  color: var(--text-color);
  line-height: 1.3;
  margin: spacing(4) 0 spacing(2) 0;
}
