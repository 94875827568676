@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spacing.scss';

$checkboxSize: 18px;
$checkboxPadding: 3px;
$checkboxBorderWidth: 2px;

.checkboxButton {
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  width: $checkboxSize;
  height: $checkboxSize;
  background-color: $white;
  border: solid $checkboxBorderWidth $cloudy;
  user-select: none;

  &Disabled {
    background-color: $ebb;
    border-color: $cloudy;
    opacity: 0.5;
  }
}

.checkboxChecked {
  background-color: $lightblueAlternative;
  border-color: transparent;

  &Disabled {
    background-color: var(--disabled-color);
  }

  > svg {
    position: absolute;
    left: -$checkboxBorderWidth;
    top: -$checkboxBorderWidth;
  }
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: spacing(3);
  margin: 0;

  height: $checkboxSize;
  line-height: $checkboxSize;
  color: var(--text-color);
  font-size: $small-font-size;
  white-space: nowrap;
  @include clickable;
  @include overflowEllipsis();

  input {
    @include visuallyHidden;

    &:focus-visible + span {
      border: solid $checkboxBorderWidth var(--accent-color);
    }
  }

  &Disabled {
    color: var(--disabled-color);
    cursor: default;
  }
}
