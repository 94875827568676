@import './variables';

:global {
  .pilot-tracker-theme {
    --primary-color: #{$white};
    --secondary-color: #{$white};
    --tertiary-color: #{$white};
    --accent-color: #{$corn};
    --block-color: #{$white};
    --text-color: #{$darkblue};
    --text-alt-color: #{$darkblue};
    --border-color: #{$lightgrey};
    --dropdown-border-color: #{$mediumgrey};
    --disabled-color: #{$greyish-orange};
    --dark-rollover-color: #{$white};

    &--dark {
      --primary-color: #{$darkblue};
      --secondary-color: #{$catalinaBlue};
      --tertiary-color: #{$darkerBlue};
      --accent-color: #{$mostly-pure-yellow};
      --block-color: #{$alto};
      --text-color: #{$white};
      --text-alt-color: #{$catalinaBlue};
      --border-color: #{$veryLightBlue};
      --dropdown-border-color: #{$darkblue};
      --disabled-color: #{$veryLightBlue};
      --dark-rollover-color: #{$mediumblue};

      .mapboxgl-ctrl-group {
        background-color: var(--block-color);
        filter: drop-shadow(0 0 1px var(--border-color));

        .mapboxgl-ctrl-icon {
          &.mapboxgl-ctrl-zoom-in {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23dedede;' d='M 10 6 C 9.446 6 9 6.4459904 9 7 L 9 9 L 7 9 C 6.446 9 6 9.446 6 10 C 6 10.554 6.446 11 7 11 L 9 11 L 9 13 C 9 13.55401 9.446 14 10 14 C 10.554 14 11 13.55401 11 13 L 11 11 L 13 11 C 13.554 11 14 10.554 14 10 C 14 9.446 13.554 9 13 9 L 11 9 L 11 7 C 11 6.4459904 10.554 6 10 6 z'/%3E %3C/svg%3E");
          }
          &.mapboxgl-ctrl-zoom-out {
            border-top-color: var(--border-color);
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23dedede;' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E %3C/svg%3E");
          }
        }
      }
    }
  }
}
