@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';
@import '../../styles/breakpoints.scss';

.fullVesselDetails {
  display: flex;
  color: var(--text-color);
  gap: 40px;

  @include breakpoint(screen-lg, max) {
    flex-direction: column;
  }
}
.imageContainer {
  min-width: 240px;
  @include breakpoint(screen-sm, min) {
    max-width: 380px;
  }
}
.image {
  display: block;
  max-width: 100%;
  height: auto;
}

.detailsListContainer {
  flex: 1 0 min-content;
  max-width: 70rem;
}

.shipName {
  margin: 0 0 spacing(4);
  font-size: 30px;
}

.groupHeading {
  margin: spacing(4) 0 spacing(2);
  font-family: $font-family-strong;
  font-size: $default-font-size;
  color: $mediumblue;
}

.detailsList {
  margin-bottom: spacing(8);
  gap: spacing(8);
  font-size: $small-font-size;

  @include breakpoint(screen-md, min) {
    columns: 2;
  }
  .group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: spacing(2) 0;
  }

  .defLabel,
  .defValue {
    margin: 0;
    padding: 0;
  }

  .defLabel {
    break-inside: avoid;
    padding-right: spacing(2);
    flex: 0 0 40%;
    font-family: $font-family-regular;
  }

  .defValue {
    position: relative;
    flex: 1 0 0;
    padding-left: spacing(2);
    max-width: 100%;
    font-family: $font-family-strong;
    white-space: normal;

    &::before {
      content: ': ';
      position: absolute;
      left: 0;
    }
  }
}
