@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.dropoutContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--border-color);
  height: 100%;
}

.dropoutButton {
  padding: 0 spacing(4);
  height: $headerHeight;
  font-size: $x-small-font-size;
  font-family: $font-family-strong;
  text-transform: uppercase;
  color: var(--text-color);

  svg {
    transform: translateY(-2px);
  }

  &.open {
    background-color: var(--primary-color);

    svg {
      transform: translateY(-2px) rotate(180deg);
    }
  }
}

.dropoutMenu {
  position: absolute;
  left: -1px;
  right: -1px;
  top: calc(100% - 1px);
  margin: 0;
  border: 1px solid var(--border-color);
  padding: 0;
  color: var(--text-color);
  list-style-type: none;
  background-color: var(--primary-color);
}

.dropOutMenuButton {
  display: block;
  width: 100%;
  padding: spacing(2) spacing(5);
  font-size: $x-small-font-size;
  font-family: $font-family-strong;
  text-transform: uppercase;
  color: var(--text-color);
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    background: $lightblue;
  }
}

.activeValue {
  display: inline-block;
  width: 1em;
  text-align: center;
}
