@import '../../styles/spacing.scss';
@import '../../styles/variables.scss';

.container {
  position: absolute;
  right: 0;
  top: 0;
  padding: spacing(2);
}

.icon {
  fill: white;
}
