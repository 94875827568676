@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/spacing.scss';

.navigationDrawer {
  $menu-width: 335px;
  overflow: hidden;

  position: absolute;
  top: $headerHeight;
  bottom: 0;
  left: 0;
  height: calc(100% - #{$headerHeight});
  width: $menu-width;
  max-width: 100%;
  background-color: var(--primary-color);
  border-top: solid 1px var(--border-color);
  border-right: solid 1px var(--border-color);
  z-index: $account-menu-index;
  transform: translateX(-100%);
  transition: 0.2s transform;
  color: var(--text-color);

  @include breakpoint(screen-md, max) {
    width: 100vw;
  }

  &Open {
    transform: translateX(0);
  }

  &Overlay {
    position: absolute;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100% - #{$headerHeight});
    z-index: $account-menu-overlay-index;
    background-color: $shadowColor;
  }

  .entry {
    border-bottom: solid 1px var(--border-color);
    padding: 0;
    display: flex;

    a {
      color: inherit;
      cursor: pointer;

      .text {
        pointer-events: none;
      }
    }

    .inner,
    &.inner {
      @include clickable();

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.25rem 0.75rem;
      color: inherit;
      text-decoration: none;
    }

    .text {
      font-family: $font-family-strong;
      font-size: $small-font-size;
      padding: 1.25rem 0.75rem;
    }

    &Small {
      .text {
        font-size: $x-small-font-size;
        padding: 0.5rem 0.75rem;
      }
      .inner,
      &.inner {
        padding: 0.5rem 0.75rem;
      }
    }
    &Inactive {
      background-color: var(--tertiary-color);
      .inner,
      &.inner {
        cursor: default;
      }

      .text {
        opacity: 0.6;
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &Top {
      margin-bottom: auto;
    }

    &Bottom {
      margin-top: auto;

      .entry {
        border-top: solid 1px var(--border-color);
        border-bottom: none;
      }
    }
  }
}
