@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/mixins.scss';

$outside-month-background-color: rgb(228, 228, 228);
$outside-month-text-color: #b3ada7;
$time-picker-background-color: #f2f2f2;
$day-cell-size: spacing(8);

:global {
  @import '~react-datepicker/dist/react-datepicker';

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__input-container {
    input {
      @include input;
    }
  }

  .react-datepicker {
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 0;
    font-family: $font-family-strong;
    background: $lightgrey;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: $lightgrey;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: $lightgrey;
  }

  .react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle::after {
    border-left-color: $lightgrey;
  }

  .react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle::after {
    border-right-color: $lightgrey;
  }

  .react-datepicker__header {
    border-bottom: 0;
    padding: spacing(2) 0 spacing(1);
    background: none;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: normal;
    color: $darkblue;
  }

  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    margin: (spacing(1) / 2) 0;
    border-radius: 0;
    width: $day-cell-size;
    height: $day-cell-size;
    line-height: $day-cell-size;
    color: $darkblue;
  }

  .react-datepicker__day-name {
    font-family: $font-family-regular;
    text-transform: uppercase;
    color: $darkblue;
  }

  .react-datepicker__navigation {
    top: 4px;

    &--next--with-time:not(&--next--with-today-button) {
      right: 110px;
    }

    ::before {
      border-color: $lightblueAlternative;
    }

    &:hover {
      ::before {
        border-color: $darkblue;
      }
    }
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
    color: $darkblue;
    background: $white;
  }

  .react-datepicker__day {
    &:hover {
      border-radius: 0;
      color: var(--text-color);
      background: $lightblueAlternative;
    }

    &--today {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 4px;
        transform: translateX(-50%);
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: $green;
      }
    }

    &--outside-month,
    &--disabled {
      font-family: $font-family-regular;
      color: $outside-month-text-color;
      background: $outside-month-background-color;
    }

    &--in-range,
    &--in-selecting-range {
      color: var(--text-color);

      &,
      &:not(.react-datepicker__day--selected, .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
        background-color: rgb(113, 184, 211);
      }
    }

    &--selected {
      color: var(--text-color);
      background: $lightblueAlternative;
    }

    &--selecting-range-start,
    &--selecting-range-end {
      position: relative;

      &,
      &:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
        background-color: $lightblueAlternative;
      }

      &::after {
        content: '';
        position: absolute;
      }
    }

    &--selecting-range-start:not(&--selecting-range-end) {
      &::after {
        @include css-triangle(right, $lightblueAlternative, $day-cell-size, 4px);
        right: auto;
        left: 100%;
      }
    }

    &--selecting-range-end:not(&--selecting-range-start) {
      &::after {
        @include css-triangle(left, $lightblueAlternative, $day-cell-size, 4px);
        left: auto;
        right: 100%;
      }
    }
  }

  .react-datepicker__time-container {
    margin-left: spacing(4);
    border-left: 0;
    width: 110px;
    background: $time-picker-background-color;

    .react-datepicker__time {
      border-radius: 0;
      background: none;

      .react-datepicker__time-box {
        width: 110px;

        ul.react-datepicker__time-list {
          scrollbar-color: rgba($darkblue, 0.5) rgba($white, 0);
          scrollbar-width: thin;

          li.react-datepicker__time-list-item {
            font-family: $font-family-regular;
            color: $darkblue;

            &,
            &--selected {
              font-weight: normal;
            }

            &--selected {
              font-family: $font-family-strong;
              color: var(--text-color);
            }
          }
        }
      }
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    &:hover {
      background: #d2e7f0;
    }

    &--selected {
      &,
      &:hover {
        background: $lightblueAlternative;
      }
    }
  }
}

.dropoutTriangle {
  @include css-triangle(top, $darkblue, spacing(1), spacing(2));
}

.dateRangeFilterContainer {
  display: flex;
  padding: 0;
  font-size: $small-font-size;
  color: $white;
  background: $darkblue;
}

.datePickerContainerGroup {
  display: flex;
  gap: spacing(4);
  padding: spacing(0) spacing(4);
}

.datePickerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 50%;
  padding-top: spacing(2);
  padding-bottom: spacing(2);
}

.datePicker {
  background: url(./calendar.svg) right spacing(1) center no-repeat;
  background-size: 20px;
}

.datePickerLabel {
  display: inline-block;
}

.datePickerLabel {
  margin-right: spacing(2);
}

.button {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: auto;
  text-transform: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  color: $white;
}

.dropoutControlsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: spacing(2);
  gap: spacing(2);
}

.dropoutButton {
  @include button($lightblue, $white, $white, $darkerBlue);
  border-radius: 3px;
}

.dropoutButtonContainer {
  display: flex;
  justify-content: space-between;
  gap: spacing(2);
}

.calendarIconHeader {
  --calendar-icon-color: #{$mediumblue};
  width: 16px;
}

.resetButton {
  font-family: $font-family-strong;
  font-size: $x-small-font-size;
  text-decoration: underline;
  text-transform: uppercase;
  color: var(--text-color);

  &:disabled {
    cursor: not-allowed;
    color: #b3ada7;
  }
}

.radioButtonLabel {
  font-family: $font-family-regular;
}

// Vertical display
.vertical {
  .dateRangeFilterContainer {
    flex-direction: column;
  }

  .datePickerContainerGroup {
    padding: spacing(2) spacing(4);
    flex-direction: column;
    max-height: 70vh;
    overflow: auto;
  }

  .datePickerContainer {
    padding: 0;

    + .datePickerContainer {
      border-left: 0;
    }
  }

  .dropoutControlsContainer {
    padding-left: spacing(4);
    padding-right: spacing(4);
    flex-direction: column;
    align-items: flex-end;
  }
}
