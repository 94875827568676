@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';
@import '../../styles/breakpoints.scss';

$overlaySpacing: spacing(3);

.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--text-color);
  background-color: var(--primary-color);
}

.fullScreenOverlayTopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.fullScreenOverlayTitle {
  margin: 0;
  padding: 0 $overlaySpacing;
  font-size: $x-small-font-size;
  font-family: $font-family-strong;
  text-transform: uppercase;
}

.fullScreenOverlayCloseButton {
  border-left: 1px solid var(--border-color);
  padding: $overlaySpacing;
  border-radius: 0;
  font-weight: normal;
  background: none;

  &:focus,
  &:hover {
    background: var(--dark-rollover-color);
  }
}

.fullScreenOverlayCloseButtonText {
  color: var(--text-color);
  font-size: $x-small-font-size;
  font-family: $font-family-regular;
}

.fullScreenOverlayCloseButtonIcon {
  transform: translateY(-1px);
  display: inline-block;
  vertical-align: middle;
  fill: var(--text-color);
}

.fullScreenOverlayContent {
  overflow: auto;
  padding: $overlaySpacing;

  @include breakpoint(screen-sm, min) {
    padding: $overlaySpacing * 2;
  }
  @include breakpoint(screen-md, min) {
    padding: $overlaySpacing * 3;
  }
}
