@import '../../styles/variables';
@import '../../styles/spacing';
@import '../../styles/mixins';

@mixin row-label($color) {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 9px;
    height: 100%;
    background-color: rgba($color, 0.5);
  }
}

.tinyTable {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  flex-direction: column;
  border-spacing: 0;
  background-color: var(--primary-color);
  font-family: $font-family-medium;
  color: var(--text-color);

  .tinyTableHead {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing * 2;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--secondary-color);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }

  .search {
    position: relative;
    width: 100%;
  }

  .searchIcon {
    position: absolute;
    height: 0.75rem;
    width: 0.75rem;
    padding: 0;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    color: $catalinaBlue;
    pointer-events: none;
  }

  .searchInput {
    @include input(2rem);

    &:not(:placeholder-shown) {
      ~ .searchIcon {
        display: none !important;
      }
    }
  }

  .helpIconSelected {
    background-color: $white;
  }
}

.row {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: 0.4rem 0 0;

  .rowTitle {
    display: flex;
    padding: 0.5rem 1rem;

    &.labelAutomatic {
      @include row-label($blue-lagoon);
    }
    &.labelCancelled {
      @include row-label($darkred);
    }
  }

  .subRow {
    display: flex;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    font-size: $x-small-font-size;
    &:last-child {
      border-bottom: none;
    }
  }

  .portStatusCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(1);
    margin-right: auto;
  }

  .button {
    align-self: flex-end;
    background-color: $catalinaBlue;
    color: var(--block-color);
    padding: 0.3rem 0.7rem;
    border-radius: 24px;
    font-family: $font-family-strong;
    margin: 1rem 0;
    white-space: nowrap;
    margin-left: auto;

    &.isActive {
      background-color: $shadowColor;
    }
  }

  .sectionAction {
    color: var(--text-color);
    font-family: $font-family-regular;
    padding: 0.5rem 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .expandedView {
    background-color: $shadowColor;
    padding: 0.5rem 0;

    .subRow {
      padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    }
  }

  .detailsList {
    font-size: $small-font-size;
    display: flex;
    flex-direction: column;

    .group {
      display: flex;
      gap: 5px;
      flex-direction: column;
      padding: spacing(2) 0;

      .label {
        margin: 0;
        break-inside: avoid;
        font-family: $font-family-strong;
      }

      .value {
        margin: 0;
        font-family: $font-family-regular;
      }
    }
  }
}
