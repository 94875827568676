@import '../../../../styles/mixins';
@import '../../../../styles/spacing';

.header {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: spacing(8);
}

.backButton {
  @include clickable();
  width: 100%;
  display: flex;
  padding: 0;
}
