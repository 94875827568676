@import '../../styles/spacing';
@import '../../styles/mixins';

$spinnerSize: spacing(6);

.lightBackgroundSpinner {
  @include spinner($spinnerSize, 0);
}

.spinnerWrapper {
  @include centerWrapper;
}

.loaderInline {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: $spinnerSize;
  height: $spinnerSize;
}
